import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import '../../styles/components/gallery.scss';

import SwiperCore, {
  Keyboard,
  Navigation,
  Pagination
} from 'swiper';

SwiperCore.use([Keyboard, Navigation, Pagination]);

export default function Gallery( props ) {
	return (
		<article className={ 'story-section gallery' }>
			<Swiper 
				keyboard={{
					enabled: true
				}} 
				navigation={{
					nextEl: '.next'
				}}
				pagination={{
					el: '.pagination',
					type: 'fraction'
				}}
				loop={true}
				speed={0}
				simulateTouch={false}
			>
				{props.gallery && props.gallery.map((image, i) => (
					<SwiperSlide modules={[Keyboard, Navigation]} key={i}>
						<figure>
							<img alt={ image.alt } className={ 'cover' } src={ image.localFile.childImageSharp.fixed.src } />
						</figure>
						<div className="flex">
							{ image.caption && (
								<div className={ 'caption text:sm uppercase' } dangerouslySetInnerHTML={{ __html: image.caption }} />
							)}
							<div className={ 'desktop text:sm uppercase pagination' }></div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>		
			<nav className={ 'next flex uppercase text:sm' }>
				<p><span className={ 'screen-reader-text' }>Next</span></p>
			</nav>
		</article>
	);
}
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../../styles/components/image-grid.scss';

export default function ImageGrid( props ) {
	return (
		<article className={ 'story-section image-grid' }>
			<div>
				{ props.gallery && props.gallery.map(( image ) => (
					<figure className={ 'ar ar-grid' }>
						<GatsbyImage className={ 'cover' } image={ image.localFile.childImageSharp.gatsbyImageData }  />
					</figure>
				)) }
			</div>
			{ props.caption && (
				<p className={ 'caption text:sm uppercase' }>{ props.caption }</p>
			)}
		</article>
	);
}
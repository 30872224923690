import React from 'react';

import Overview from './Overview';
import Gallery from './Gallery';
import TextContent from './TextContent';
import Team from './Team';
import ImageGrid from './ImageGrid';
import LogosGrid from './LogosGrid';
import ThreeColumnThumbnail from './ThreeColumnThumbnail';

export default function ContentBlocks( props ) {
	return (
		<div className={ 'flex spacious' }>
			<div>
				<Overview content={ props.content } />
				{ props.sections.researchSection.map((section, index) => {
					if( section.fieldGroupName == 'ContentNode_Researchsections_ResearchSection_Gallery' ) {
						return (
							<Gallery sectionIndex={ index } gallery={ section.gallery } />
						)
					}

					if( section.fieldGroupName == 'ContentNode_Researchsections_ResearchSection_TextContent' ) {
						return (
							<TextContent sectionIndex={ index } content={ section.sectionContent } />
						)
					}

					if( section.fieldGroupName == 'ContentNode_Researchsections_ResearchSection_ImageGrid' ) {
						return (
							<ImageGrid sectionIndex={ index } caption={ section.caption } gallery={ section.images } />
						)
					}

					if( section.fieldGroupName == 'ContentNode_Researchsections_ResearchSection_Team' ) {
						return (
							<Team sectionIndex={ index } content={ section.teamSection } />
						)
					}

					if( section.fieldGroupName == 'ContentNode_Researchsections_ResearchSection_LogosGrid' ) {
						return (
							<LogosGrid sectionIndex={ index } heading={ section.heading } logos={ section.logos } />
						)
					}
					
					if( section.fieldGroupName == 'ContentNode_Researchsections_ResearchSection_ThreeColumnThumbnail' ) {
						return (
							<ThreeColumnThumbnail sectionIndex={ index } thumbnails={ section.thumbnail } caption={ section.caption } />
						)
					}
				})}
			</div>
		</div>
	);
}
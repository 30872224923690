import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../../styles/components/logos-grid.scss';

export default function LogosGrid( props ) {
	return (
		<article className={ 'logos-grid flex spacious' }>

			{ props.heading && (
				<h2 className={ 'text:sm text:sm-tracked uppercase' }>{ props.heading }</h2>
			)}

			{ props.logos && (
				<ul className={ 'flex' }>
					{ props.logos.map(( logo ) => (
						<li>
							{ logo.attachmentLink.mediaItemLink && (
								<a target="_blank" rel="noreferrer" href={ logo.attachmentLink.mediaItemLink }>
									<GatsbyImage image={ logo.localFile.childImageSharp.gatsbyImageData }  />
								</a>
							)}

							{ !logo.attachmentLink.mediaItemLink && (
								<GatsbyImage image={ logo.localFile.childImageSharp.gatsbyImageData }  />
							)}
						</li>
					)) }
				</ul>
			)}

		</article>
	);
}
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../../styles/components/three-column-thumbnail.scss';

export default function ThreeColumnThumbnail( props ) {
	return (
		<>
			<article className={ 'story-section three-column-thumbnail' }>
				<ul>
					{ props.thumbnails.map((image) => (
						<li>
							<figure className={ 'ar ' }>
								<GatsbyImage className={ 'cover' } image={ image.image.localFile.childImageSharp.gatsbyImageData }  />
							</figure>
						</li>
					)) }
				</ul>

				{ props.caption && (
					<p className={ 'uppercase text:sm' }>{ props.caption }</p>
				)}
			</article>
		</>
	);
}
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Hero from '../components/research/Hero';
import HeroImages from '../components/research/HeroImages';
import ResearchNavigation from '../components/navigation/ResearchNav';
import Content from '../components/research/Content';
import Title from '../components/utils/Title';
import Footer from '../components/utils/Footer';
import Collaborator from '../components/research/Collaborator';

import WordMarque from '../components/WordMarque';
import Seo from '../components/Seo';

import '../styles/components/story.scss';

const ResearchTemplate = ({ data }) => {
	return (
		<>
			{console.log(data.wpResearch)}
			<Layout>
				<Seo 
					scheme={ 'scheme:secondary' } 
					title={ data.wpResearch.title } 
				/>
				<WordMarque />
				<article className={ 'story research' }>
					<ResearchNavigation />
					<Hero>
						<Title title={ data.wpResearch.title } alternative={ data.wpResearch.alternativeTitle } />
						<HeroImages images={ data.wpResearch.heroBlock } />

						{ data.wpResearch.collaborator && (
							<Collaborator logo={ data.wpResearch.collaborator } />
						)}
					</Hero>
					<Content content={ data.wpResearch.content } sections={ data.wpResearch.researchSections } />
				</article>
                <Footer classes={ '' } desktop={ data.wpResearch.footerImage.footerImageDesktop } mobile={ data.wpResearch.footerImage.footerImageMobile } />
			</Layout>
		</>
	);
}

export default ResearchTemplate;

export const query = graphql`
	query ResearchItem($id: String) {
	  wpResearch(id: {eq: $id}) {
	    id
	    title
	    slug
	    content
	    alternativeTitle {
	      alternativeTitle
	      fieldGroupName
	    }
        footerImage {
	        footerImageMobile {
	          localFile {
	            childImageSharp {
	              gatsbyImageData
	            }
	          }
	        }
	        footerImageDesktop {
	          localFile {
	            childImageSharp {
	              gatsbyImageData
	            }
	          }
	        }
        }
	    collaborator {
	      image {
	        localFile {
	          childImageSharp {
	            gatsbyImageData(placeholder: NONE)
	          }
	        }
	      }
	    }
	    heroBlock {
	      desktopImage {
	        localFile {
	          childImageSharp {
	            gatsbyImageData(placeholder: NONE)
	          }
	        }
	      }
	      mobileImage {
	        localFile {
	          childImageSharp {
	            gatsbyImageData(placeholder: NONE)
	          }
	        }
	      }
	    }
	    gallery {
	      gallery {
	        localFile {
	          childImageSharp {
	            fluid {
	              src
	            }
	          }
	        }
	      }
	    }
	    researchSections {
	      researchSection {
	        ... on WpContentNode_Researchsections_ResearchSection_TextContent {
	          fieldGroupName
	          sectionContent
	          sectionTitle
	        }
	        ... on WpContentNode_Researchsections_ResearchSection_Gallery {
	          fieldGroupName
	          sectionTitle
	          gallery {
	            altText
	            caption
	            localFile {
	              childImageSharp {
	                fixed {
	                  src
	                }
	              }
	            }
	          }
	        }
	        ... on WpContentNode_Researchsections_ResearchSection_Team {
	          fieldGroupName
	          teamSection {
	            function
	            teamMembers {
	              name,
	              url
	            }
	          }
	        }
	        ... on WpContentNode_Researchsections_ResearchSection_LogosGrid {
	          fieldGroupName
	          heading
	          logos {
	            altText
	            localFile {
	              childImageSharp {
	            	gatsbyImageData(placeholder: NONE)
	              }
	            }
	            attachmentLink {
	              mediaItemLink
	            }
	          }
	        }
	        ... on WpContentNode_Researchsections_ResearchSection_ImageGrid {
	          caption
	          fieldGroupName
	          images {
	            localFile {
	              childImageSharp {
	            	gatsbyImageData(placeholder: NONE)
	              }
	            }
	          }
	        }
	        ... on WpContentNode_Researchsections_ResearchSection_ThreeColumnThumbnail {
	          fieldGroupName
	          thumbnail {
	            image {
	              localFile {
	                childImageSharp {
	            		gatsbyImageData(placeholder: NONE)
	                }
	              }
	            }
	          }
	          caption
	        }
	      }
	    }
	  }
	}
`;
import React from 'react';
import '../../styles/components/team.scss';

export default function Team( props ) {
	return (
		<article className={ 'team flex spacious' }>
			<h2 className={ 'text:sm text:sm-tracked uppercase' }>Team</h2>

			<div class="flex spacious">
				{ props.content && props.content.map(( team ) => (
					<div>
						<h2 className={ 'text:sm text:sm-tracked uppercase' }>{ team.function }</h2>
						{console.log(team)}
						<ul>
							{ team.teamMembers && team.teamMembers.map(( member ) => (
								<>
									{ member.url && (
										<li><a target="_blank" rel="noreferrer" href={ member.url }>{ member.name }</a></li>
									)}

									{ !member.url && (
										<li>{ member.name }</li>
									)}
								</>
							)) }
						</ul>
					</div>
				)) }
			</div>
		</article>
	);
}
import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

export default function Overview( props ) {
  return (
    <StaticQuery
      query={graphql`
        query OverviewContent {
    		  allWpPage {
    		    nodes {
    		      about {
    		        overviewContent
    		      }
    		    }
    		  }
        }
      `}
      render={overview => (
        <article data-section-panel="section-lead" className={ 'story-section overview text:copy' } dangerouslySetInnerHTML={{ __html: props.content }} />
      )}
    />
  )
}
import React, { useEffect,  } from 'react';
import initialContrast from '../../helpers/initialContrast';
import fixedWordMarque from '../../helpers/fixedWordMarque';
import ContentBlocks from './ContentBlocks';

export default function Content( props ) {

    useEffect(() => {
        
        initialContrast();
        window.addEventListener('scroll', fixedWordMarque);
        return () => {
            window.removeEventListener('scroll', fixedWordMarque);
        };
    });

	return (
		<section className={ 'story-panel colour:secondary' }>
			<ContentBlocks content={ props.content } sections={ props.sections } />
		</section>
	);
}
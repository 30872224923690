import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../../styles/components/collaborator.scss';

export default function Collaborator( props ) {
	return (
		<aside className={ 'collaborator' }>
            <div className={ 'flex spacious' }>
                { props.logo.image && (
                    <>
                        <div className={ 'flex' }>
                            <p>Building Ways</p>
                            <span>×</span>
                        </div>
                        <figure>
                            <GatsbyImage image={ props.logo.image.localFile.childImageSharp.gatsbyImageData }  />
                        </figure>
                    </>
                )}
            </div>
		</aside>
	);
}